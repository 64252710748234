<template>
  <v-sheet class="treemap">
    <low-balance-alert
      @clickedProduct="clickedLowBalanceProduct"
      v-if="!loading"
      :balanceService="startBalanceService"
    />

    <v-divider class="my-4" />
    <div class="text-subtitle-2 ma-2 text-center">
      {{ $vuetify.lang.t("$vuetify.dashboard.inventoryBalance") }}
    </div>

    <div class="d-flex justify-space-between">
      <v-btn-toggle @change="changeRoot" v-model="group">
        <v-btn v-for="node in rootProducts" :key="node.index">
          {{ node.name }}
        </v-btn>
      </v-btn-toggle>

      <div class="my-auto">
        <v-btn
          v-if="displayMode == 'list'"
          @click="toggleDisplayMode"
          icon
          color="primary"
        >
          <v-icon>mdi-chart-tree</v-icon>
        </v-btn>
        <v-btn
          v-if="displayMode == 'treechart'"
          @click="toggleDisplayMode"
          icon
          color="primary"
        >
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
      </div>
    </div>
    <div style="border: 1px solid #ccc">
      <v-slide-group
        v-model="subGroupIndex"
        v-if="products && displayMode == 'treechart'"
        show-arrows
      >
        <v-slide-item
          class="ma-4"
          v-for="subgroup in subGroups"
          :key="subgroup.id"
          v-slot="{ active, toggle }"
        >
          <div class="d-flex" @click="toggle">
            <div
              class="rounded-circle mr-3 my-auto"
              :style="`backgroundColor: ${subgroup.color}; width: 18px; height: 18px;`"
            >
              <v-icon v-if="active" small class="mb-2">mdi-check</v-icon>
            </div>
            <div
              :class="`text-caption legend ${active ? 'selected-legend' : ''}`"
            >
              {{ subgroup.name }}
            </div>
          </div>
        </v-slide-item>
      </v-slide-group>
      <div v-if="displayMode == 'treechart'">
        <v-sheet
          ref="treemapContainer"
          :elevation="2"
          class="d-flex flex-column justify-center"
        >
          <v-skeleton-loader v-if="loading" type="card-heading, image, image" />
          <div style="position: relative">
            <d3-treemap
              v-resize="onResize"
              v-if="products"
              :width="containerWidth"
              :height="windowSize.y * 0.8"
              :data="filteredProducts"
              :nameFn="nameFn"
              :valueFn="nodeValue"
              :labelFn="labelFn"
              :colorFn="colorFn"
              :fontSize="12"
              :key="ts"
              @click="clickedTreeMap"
              @drillDown="drillDownTreemap"
            />
            <v-overlay :value="loadingBalance" absolute>
              <v-progress-circular indeterminate></v-progress-circular>
            </v-overlay>
          </div>
        </v-sheet>
      </div>
      <div v-if="displayMode == 'list'">
        <v-text-field
          class="ma-4"
          hide-details
          dense
          v-model="searchTerm"
          clearable
          outlined
          label="Search Product"
        />
        <div class="d-flex" style="position: relative">
          <v-treeview
            :active.sync="activeProducts"
            :search="searchTerm"
            :items="filteredProductsAsList"
            return-object
            activatable
            selected-color="primary"
            :class="isMobile ? 'flex-grow-1' : ''"
          >
            <template v-slot:prepend="{ item }">
              <v-icon v-if="item.children && item.children.length > 0"
                >mdi-folder-outline</v-icon
              >
            </template>
          </v-treeview>
          <v-divider vertical v-if="!isMobile" class="mx-2" />
          <div class="ma-4 flex-grow-1" v-if="!isMobile">
            <div v-if="activeProducts && activeProducts.length > 0">
              <v-scroll-y-transition mode="out-in">
                <product-details
                  v-if="productNode"
                  :product="productNode"
                  :key="productNode.id"
                />
              </v-scroll-y-transition>
            </div>
            <div class="text-caption font-weight-light" v-else>
              No product selected
            </div>
          </div>

          <v-overlay :value="loadingBalance" absolute>
            <v-progress-circular indeterminate></v-progress-circular>
          </v-overlay>
        </div>
      </div>
    </div>
    <v-dialog
      style="z-index: 1000"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      v-model="showDetails"
    >
      <v-sheet
        v-if="activeProducts && (displayMode == 'treechart' || isMobile)"
        class="text-center px-4"
        style="overflow: scroll"
      >
        <v-btn class="mt-6" text color="red" @click="showDetails = false">
          {{ $vuetify.lang.t("$vuetify.close") }}
        </v-btn>
        <v-divider />
        <product-details
          v-if="productNode"
          :product="productNode"
          :key="productNode.id"
        />
      </v-sheet>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import * as d3 from "d3";
import moment from "moment";
const ProductDetails = () =>
  import("@/components/dashboard/ProductDetails.vue");
const LowBalanceAlert = () =>
  import("@/components/dashboard/LowBalanceAlert.vue");
const D3Treemap = () => import("@/components/common/D3Treemap.vue");
import { fetchProducts, getProductLeafIds, searchProductByEngName} from "@/services/productService.js";
import { findByProductId } from "@/services/productTreeService.js";
import { tagReservedInventories } from "@/services/inventoryReservation.js";

export default {
  data: () => {
    return {
      loading: true,
      loadingBalance: false,
      startDate: new moment().add("day", -5),
      endDate: new moment(),
      showDetails: false,
      totalPages: null,
      products: null,
      windowSize: { x: 800, y: 500 },
      containerWidth: null,
      ts: Date.now(),
      group: 0,
      subGroup: null,
      displayMode: "treechart",
      subGroupIndex: null,
      searchTerm: null,
      activeProducts: [],
      rootProducts: null,
    };
  },
  components: {
    D3Treemap,
    ProductDetails,
    LowBalanceAlert,
  },
  computed: {
    ...mapGetters("inventoryBalances", ["startBalanceService"]),
    filteredProducts() {
      if (this.subGroup && this.displayMode == "treechart") {
        return this.subGroup;
      }

      if (!this.products) {
        return [];
      }
      return this.products.children[this.group];
    },
    filteredProductsAsList() {
      if (this.filteredProducts && this.filteredProducts.children) {
        return this.filteredProducts.children;
      } else {
        return [];
      }
    },
    subGroups() {
      let categories = [];

      if (this.rootProducts && this.rootProducts.length > 0 && this.products) {
        const selectedRootProduct = this.rootProducts[this.group];
        const node = this.products.children[selectedRootProduct.index];
        categories =
          node && node.children
            ? node.children.filter((c) => c.children && c.children.length > 0)
            : [];

        categories.forEach((c) => {
          const id = `${selectedRootProduct.id}.${c.id}`;
          c.color = this.colorFn(id);
        });
      }

      return categories;
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    productNode() {
      if (this.activeProducts && this.activeProducts.length > 0) {
        return this.activeProducts[0];
      } else {
        return null;
      }
    },
  },
  async mounted() {
    this.onResize();
    this.initTreemapFunctions();

    const { rootNode, rootProducts, leafIds } = await fetchProducts();
    
    await this.initProductBalance(rootNode, this.group);

    this.products = rootNode;
    this.rootProducts = rootProducts;

    this.loading = false;
  },
  watch: {
    subGroupIndex(index) {
      if (index > -1) {
        this.subGroup = this.subGroups[index];
      } else {
        this.subGroup = null;
      }
      this.searchTerm = null;
      this.ts = Date.now();
    },
    activeProducts(val) {
      if (
        val &&
        val.length > 0 &&
        this.isMobile &&
        this.displayMode == "list"
      ) {
        const product = val[0];
        if (!product.children || product.children.length == 0) {
          this.showDetails = true;
        }
      }
    },
  },
  methods: {
    ...mapActions("inventoryBalances", ["loadInventoryBalance"]),
    ...mapActions("processWorksheet", ["fetchProcessWorksheets"]),
    ...mapActions("messages", [
      "addErrorMessage",
      "addMessage",
      "addSuccessMessage",
    ]),
    async changeRoot() {
      await this.initProductBalance(this.products, this.group);

      this.showDetails = false;
      if (this.displayMode == "list") {
        this.activeProducts = [this.subGroups[0]];
      }
      this.subGroup = null;
      this.subGroupIndex = null;
      this.ts = Date.now();

      this.loading = false;
    },
    onResize() {
      if (this.$refs.treemapContainer) {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        this.containerWidth = this.$refs.treemapContainer.$el.offsetWidth;

        const now = Date.now();
        if (now - this.ts > 100) {
          this.ts = Date.now();
        }
      }
    },
    async initProductBalance(rootNode, selectedGroup ){
      this.loadingBalance = true;

      const selectedGroupNode = rootNode.children[selectedGroup];
      let selectedGroupLeafIds = getProductLeafIds(selectedGroupNode);

      // Search for 'Slag" product
      const slag = searchProductByEngName(selectedGroupNode, 'Slag');
      if(slag){
        selectedGroupLeafIds = selectedGroupLeafIds.filter(id => id != slag.id);
      }

      await this.loadInventoryBalance({ startDate: Date.now(), productIds: selectedGroupLeafIds });

      const allInventories = await this.startBalanceService.getAllInventories();

      // console.log("all inventories", allInventories);
      await tagReservedInventories(allInventories);

      // assign inventory balance to product
      this.postOrderTraverse(selectedGroupNode);

      this.loadingBalance = false;
    },
    initTreemapFunctions() {
      this.nameFn = (node) => {
        return node.id;
      };
      this.labelFn = (node) => {
        const reserved =
          node.reservedQuantity > 0
            ? `\n${this.$options.filters.formatNumber(node.reservedQuantity)} ${
                node.measureWeight
              } (R)`
            : "";
        const remainingWeight = `\n${this.$options.filters.formatNumber(
          node.value
        )} ${node.measureWeight ? node.measureWeight:''}`;

        const label = [];
        label.push(node.name);
        label.push(remainingWeight);
        if (reserved) {
          label.push(reserved);
        }
        return label;
      };
      const fader = (color) => d3.interpolateRgb(color, "#fff")(0.2);
      this.colorFn = d3.scaleOrdinal(d3.schemePaired.map(fader));
      this.nodeValue = (node) => (node.value ? node.value : 0);
    },
    postOrderTraverse(node) {
      if (node && node.children && node.children.length !== 0) {
        node.children.forEach((child) => {
          this.postOrderTraverse(child);
          // const childrenSum = node.children.reduce((sum, n) => (sum += n.value), 0);
          // node.value = childrenSum;
        });
      } else {
        if (this.startBalanceService) {
          const productBalance =
            this.startBalanceService.getBalanceByProduct(node);

          // get
          const productId = node.id;
          const productInventories = this.startBalanceService
            .getAllInventories()
            .filter((i) => i.productId == productId);
          const totalReservedQuantity = productInventories.reduce(
            (reservedSum, i) => (reservedSum += i.reservedQuantity),
            0
          );
          if (totalReservedQuantity > 0) {
            node.reservedQuantity = totalReservedQuantity;
          }

          let weight = productBalance ? productBalance.quantity : 0;
          //convert weight to kg based
          if (node.measureWeight == "Gram" || node.measureWeight == "g") {
            weight = weight / 1000;
          }

          node.value = weight;
        }
      }
      return true;
    },
    clickedTreeMap(id) {
      let productId = null;
      if (id && typeof id === 'string' && id.includes('.')) {
        productId = id.split(".").pop();
      } else {
        productId = id;
      }

      if (productId) {
        this.showProductDetails(productId);
      }
    },
    showProductDetails(productId) {
      const productNode = findByProductId(this.products, productId);
      this.activeProducts = [productNode];
      this.showDetails = true;
    },
    toggleDisplayMode() {
      this.displayMode = this.displayMode == "treechart" ? "list" : "treechart";

      //init active products (sub-group) if active products is empty and subGroup is selected
      if (this.displayMode == "list") {
        if (this.subGroup) {
          this.activeProducts = [this.subGroup];
        } else if (this.subGroups && this.subGroups.length > 0) {
          this.activeProducts = [this.subGroups[0]];
        }
      }

      if (this.displayMode == "treechart") {
        this.activeProducts = [];
      }
    },
    clickedLowBalanceProduct(product) {
      this.showProductDetails(product.productId);
    },
    drillDownTreemap(idStr) {
      const tokens = idStr.split(".");
      const subGroupId = tokens[tokens.length - 1];
      this.subGroupIndex = this.subGroups.findIndex((g) => g.id == subGroupId);
      this.searchTerm = null;
      this.ts = Date.now();
    },
  },
};
</script>

<style lang="scss" scoped>
.treemap {
  svg {
    font: 10px sans-serif;
  }
}

.legend {
  white-space: nowrap;
  cursor: pointer;
}

.selected-legend {
  text-decoration: underline;
  text-decoration-color: red;
  text-decoration-thickness: 2px;
}
</style>
