import Vue from "vue";
let cachedProductIds = null;
let cachedResult = null;

/**
 * get leaf node ids from root products
 */
export function getProductLeafIds(node) {
    let ids = [];
    if (node.children) {
        if (node.children.length > 0) {
            node.children.forEach((child) => {
                if (!child.children || child.children.length < 1) {
                    child.isLeaf = true;
                    ids.push(child.id);
                } else {
                    ids = ids.concat(getProductLeafIds(child));
                }
            });
        }
    }
    node.leafNodeIds = ids;
    return ids;
}

/**
 * Search for a product node by its English name ("enName") in a tree.
 * @param {Object} node - The root node of the tree to search.
 * @param {string} targetEngName - The English name to search for.
 * @returns {Object|null} - The found product node or null if not found.
 */
export function searchProductByEngName(node, targetEngName) {
    if (!node) {
        return null;
    }

    if (node.enName === targetEngName) {
        return node;
    }

    if (node.children && node.children.length > 0) {
        for (const child of node.children) {
            const foundNode = searchProductByEngName(child, targetEngName);
            if (foundNode) {
                return foundNode;
            }
        }
    }

    return null; // Product not found by English name
}



export async function fetchProducts(productIds = []) {
    const filterProductIds = productIds && productIds.length > 0 ? productIds.join(',') : null;

    // If the productIds haven't changed, return the cached result
    if (cachedResult != null && filterProductIds === cachedProductIds) {
        return cachedResult;
    }

    const params = {
        code: "PRODUCTS",
        filterIds: filterProductIds
    };

    try {
        const response = await Vue.axios.get("category/childs", { params });

        if (response.status !== 200) {
            throw new Error(`Error: Received status code ${response.status}`);
        }

        const nodes = response.data.data;
        const rootNode = {
            id: 0,
            name: "All Products",
            children: nodes,
        };

        const rootProducts = [];
        nodes.forEach((n, index) => {
          rootProducts.push({ id: n.id, index, name: n.name });
        });

        // Store the result in the cache
        cachedProductIds = filterProductIds;
        cachedResult = { rootNode, rootProducts };

        return cachedResult;
    } catch (error) {
        console.error('Error fetching products:', error.message);
        throw error;
    }
}