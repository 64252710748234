const getAllDescendantProductIds = (rootNode) => {
  const recursive = (node, ids) => {
    
    if(node && node.id){
      ids.push(rootNode.id);
    }

    if (node && node.children && node.children.length > 0) {
      for (const child of node.children) {
        ids = recursive(child, ids);
      }
    }

    return ids;
  };

  return recursive(rootNode, []);
};

const findByProductId = (rootNode, productId) => {
  if (rootNode) {
    const recursive = (node, productId) => {
      if (node && node.id == productId) {
        return node;
      } else {
        if (node && node.children) {
          let found = null;
          for (const childNode of node.children) {
            found = recursive(childNode, productId);
            if (found) {
              break;
            }
          }
          return found;
        } else {
          return null;
        }
      }
    };

    return recursive(rootNode, productId);
  } else {
    return null;
  }
};

export { getAllDescendantProductIds, findByProductId };
